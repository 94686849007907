import "jquery";

(function ($) {
	var $form = $('.js-waste-container-form');
	var $mainContent = $('.maincontent');
	var $loader = $('.js-waste-container-page-loading');
	var $submitButton = $('.js-waste-container-form input[type="submit"]');

	var $successMessage = $('.js-waste-container-success');
	var $selectWasteContainerMessage = $('.js-select-waste-container');
	var $wasteContainerValidationMessage = $('.js-selected-waste-container-not-valid');

    $form.on('submit', function (e) {
        e.preventDefault();

		$selectWasteContainerMessage.addClass('hidden');
		$wasteContainerValidationMessage.addClass('hidden');

        // Check that user has selected a waste container
        var isWasteContainerSelected = true;
        var selectedWasteContainers = $('.js-waste-container .js-waste-container-checkbox:checked');

        // At least one waste container must be selected.
        if (selectedWasteContainers.length < 1) {
			$selectWasteContainerMessage.removeClass('hidden');
            isWasteContainerSelected = false;
        }

        $.each(selectedWasteContainers, function (index, item) {
            // If a new waste container option is available, one option needs to be selected.
            var wasteContainer = $(item).parent();
            if (wasteContainer.find('.js-new-waste-container').length > 0) {
                if (wasteContainer.find('.js-new-waste-container:checked').length < 1) {
					$selectWasteContainerMessage.removeClass('hidden');
                    isWasteContainerSelected = false;
                    return;
                }
            }
        });

        if (!$form.valid() || !isWasteContainerSelected) {
            return false;
        }

		$submitButton.addClass('hidden');
		$loader.removeClass('hidden');

        setTimeout(function () {

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                success: function (result) {
					$loader.addClass('hidden');

					if (result.ContainerListNotValid && result.ContainerListNotValid === true) {
						$wasteContainerValidationMessage.text(result.Description);
						$wasteContainerValidationMessage.removeClass('hidden');

						$submitButton.removeClass('hidden');
					}
					else {
						$form.addClass('hidden');
						$mainContent.addClass('hidden');

						if (result.OngoingOrdersForAddress) {
							$successMessage.addClass('form-error');
						} else {
							$successMessage.removeClass('form-error');
						}

						$successMessage.text(result.Description);
						$successMessage.removeClass('hidden');

						window.scrollTo(0, 0);
					}
                },
                error: function (error) {
					$loader.addClass('hidden');
					$form.addClass('hidden');
					$mainContent.addClass('hidden');

					var $error = $('.js-waste-container-error');
                    if (error.statusText !== '') {
                        $error.text(error.statusText);
                    }

					$error.removeClass('hidden');

					window.scrollTo(0, 0);
                }
            });

        }, 250);
    });
})(jQuery);

(function ($) {

    var $searchField = $('.js-address-search');
    var $searchSubmit = $('.js-address-search-submit');
    var $searchResultContainer = $('.js-address-result');
    var searchTimeout = 0;
    var matrikkelSearch = false;
    var glassContainer = false;

    var resultTemplate = Handlebars.compile($("#address-result-template").html());

    var $wasteContainerList = $('.js-waste-container-list');

    var search = function (address) {
        if (address.length <= 2) {
            $searchResultContainer.html('');
            return;
        }

        $searchResultContainer.html(skTranslate["/wastecontainer"]["/searchingforaddress"]);

        var query = "address=" + address;

        if (matrikkelSearch) {
            query += "&matrikkelSearch=true";
        }

        if (glassContainer) {
			query += "&glassContainer=true";
        }

        $.getJSON('/api/renovasjonservice/GroupedAddressSearch?' + query, function (result) {

            var data = {
                Hits: result,
                TotalHits: result.length
            }

            $searchResultContainer.html(resultTemplate(data));
        });
    }

    $searchField.on('input', function () {
        $wasteContainerList.html(skTranslate["/wastecontainer"]["/chooseaddress"]);

        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(function () {
            search($searchField.val());
        }, 500);

    });

    $searchSubmit.click(function () {
        clearTimeout(searchTimeout);
        search($searchField.val());
    });

    window.wasteContainer = window.wasteContainer || {};
    window.wasteContainer.setUpAddressSearch = function (options) {
        if (options.matrikkelSearch) {
            matrikkelSearch = options.matrikkelSearch;
        }
        if (options.glassContainer) {
            glassContainer = options.glassContainer;
        }
    }
})(jQuery);

$('.js-waste-container-form').on('change', '.js-new-waste-container', function () {
    var $container = $(this).closest('.js-waste-container');

    $container.find('.js-new-container-size').val($(this).data('container-size'));
});